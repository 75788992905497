<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :current-page="page"
        :hasAdd="false"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :isCheckInputBox="false"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
        @onAdd="$router.push('./add')"
    >
    </list-template>

  </div>
</template>

<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'name',
          placeholder: '搜索测评名称'
        },
        {
          prop: 'paper_id',
          tag: 'select',
          placeholder: '筛选问卷名称',
          options: [],
          label: 'name',
          value: 'id'
        },
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '测评名称',
          showOverflowTooltip: true
        },
        {
          prop: 'paper',
          label: '问卷名称',
          showOverflowTooltip: true
        },
        {
          prop: 'start_at',
          label: '开始时间'
        },
        {
          prop: 'end_at',
          label: '结束时间'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          handle: true,
          width: '120',
          buttons: (row) => {
            let now = moment().format('x')
            let start = moment(row.start_at).format('x')
            if(Number(row.creator_id) === Number(this.$store.getters.userInfo.userinfo.id) && (now < start)){
              return [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
            }else{
              return [{ type:"view", text:"查看" }]
            }
          }
        }
      ],

      search: null,
      page: 1,
      total: 0,
      loading: true,
      // 表格中的数据
      tableData: []
    }
  },
  inject: ['reload'],
  mounted() {
    this.getPaper()
    this.$router.afterEach(async (to,from) => {
      if(from.path === "/moral/task-manage/task-manage/add" || from.path === "/moral/task-manage/task-manage/edit"){
        this.reload()
      }
    })
  },
  methods: {
    //获取问卷配置
    getPaper() {
      this.$_axios2('/api/evaluation/index/paper').then(res => {
        // console.log(res)
        if (res.data.status === 0) {
          this.searchConfig[1].options = res.data.data
          // console.log(this.searchConfig[1].options)
          this.getData()
        }
      })
    },

    // 分页被更改
    changeCurrentPage(e) {
      // console.log(e)
      this.page = e
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      this.loading = true
      let params = {...this.search}
      params.page = this.page
      this.$_axios2('/api/evaluation/index',{params}).then(res => {
        // console.log(res)
        this.loading = false
        if(res.data.status === 0){
          this.tableData = res.data.data.list
          this.total = res.data.data.page.total
        }
      }).finally(()=>this.loading=false)
    },

    // 搜索按钮被点击
    onSearch(val) {
      // console.log(val)
      this.search = val
      this.getData()
    },

    // 表格后面操作被点击
    tableHandle(row, handle) {
      const { type } = handle
      if(type === 'edit'){
        this.$router.push('./edit?id=' + row.id)
      }else if(type === 'view'){
        this.$router.push('./details?id=' + row.id)
      }else if(type === 'delete'){
        this.$confirm('删除后，用户将无法参与此测评', '提示', {
          confirmButtonText: '坚持删除',
          cancelButtonText: '点错了',
          type: 'warning'
        }).then(() => {
          this.loading = true
          this.$_axios2.delete('/api/evaluation/index/' + row.id).then(res => {
            // console.log(res)
            this.loading = false
            if(res.data.status === 0){
              this.$message({
                message: res.data.msg,
                type: 'success',
                onClose: () => {
                  this.reload()
                }
              })
            }else{
              // this.$message({
              //   message: res.data.msg,
              //   type: 'error'
              // })
            }
          }).catch(err => {
            this.loading = false
            this.reload()
          });
        }).catch(() => {});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher{
  min-width: 1250rem;
}
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }


}

.title {
  font-size: 20rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20rem;
}

.title_item {
  text-align: right;
}
</style>
